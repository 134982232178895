<template>
  <b-container>
    <b-row>
      <b-col
        :lg="6"
        :sm="8"
        :offset-lg="3"
        :offset-sm="2"
      >
        <b-alert v-model="errorState" variant="danger" dismissible>
          {{ error }}
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        :lg="4"
        :sm="8"
        :offset-lg="4"
        :offset-sm="2"
        class="mb-3"
      >
        <div class="h4 text-center">
          {{ $t('pLogin_title') }}
        </div>
        <form @submit.prevent="loginPassport">
          <b-form-group
            id="group-email"
            :label="$t('pLogin_f_email')"
            label-for="email"
            :invalid-feedback="emailInvalidFeedback"
            :state="emailState"
          >
            <b-form-input
              id="email"
              v-model="form.username"
              type="email"
              :state="emailState"
              trim
            />
          </b-form-group>

          <b-form-group
            id="group-password"
            :label="$t('pLogin_f_password')"
            label-for="password"
            class="mb-4"
            :invalid-feedback="passwordInvalidFeedback"
            :state="passwordState"
          >
            <b-form-input
              id="password"
              v-model="form.password"
              type="password"
              :state="passwordState"
              trim
            />
          </b-form-group>

          <div class="mt-8 d-flex justify-content-center">
            <b-btn
              type="submit"
              :variant="!sendBtnDisabledState ? 'primary' : 'secondary'"
              :disabled="sendBtnDisabledState"
            >
              {{ $t('pLogin_loginBtnLabel') }}
            </b-btn>
          </div>
        </form>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center text-muted small">
        v: {{ appVersion }}
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Cookie from 'js-cookie'
import nav from '~/nav'

export default {
  name: 'LoginPage',
  layout: 'clean',
  auth: true,
  data () {
    return {
      state: true,
      isEmailChanged: false,
      isPasswordChanged: false,
      authBusy: false,
      form: {
        username: '',
        password: ''
      },
      error: null
    }
  },
  computed: {
    errorState: {
      get () {
        return !!this.error && this.error.length > 0
      },
      set () {
        this.error = null
      }
    },
    emailState () {
      if (!this.isEmailChanged) {
        return true
      }
      // match email regex
      const matches = String(this.form.username)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      return this.form.username.length > 0 && !!matches
    },
    passwordState () { //
      if (!this.isPasswordChanged) {
        return true
      }
      return this.form.password.length >= 6
    },
    sendBtnDisabledState () {
      return !(!this.authBusy &&
        this.passwordState &&
        this.emailState)
    },
    emailInvalidFeedback () {
      return this.$t('pLogin_err_email')
    },
    passwordInvalidFeedback () {
      return this.$t('pLogin_err_pass')
    },
    username () {
      return this.form.username
    },
    password () {
      return this.form.password
    },
    appVersion () {
      return this.$config?.appVersion || ''
    }
  },
  watch: {
    username () {
      this.isEmailChanged = true
    },
    password () {
      this.isPasswordChanged = true
    }
  },
  methods: {
    async loginPassport () {
      this.error = null
      const data = {
        username: this.form.username,
        password: this.form.password,
        grant_type: 'password',
        client_id: this.$config.clientId,
        client_secret: this.$config.clientSecret,
        scope: '*'
      }
      const path = Cookie.get('auth.redirect')
      this.authBusy = true
      const loginRes = await this.$auth.loginWith('local', { data })
        .then((res) => {
          return this.$auth.setUserToken(res.data.access_token, res.data.refresh_token)
        })
        .catch((error) => {
          this.$auth.logout()
          if (error.response) {
            // Request made and server responded
            if (error.response.data.message) {
              this.error = `${error.response.status} ${error.response.data.message}`
            } else {
              this.error = `${error.response.status} ${this.$t('error')}`
            }
          } else if (error.request) {
            // The request was made but no response was received
            this.error = `${this.$t('error_request')}`
          } else {
            // Something happened in setting up the request that triggered an Error
            this.error = `${this.$t('error_unknown')}`
          }
        })
        .finally(() => {
          this.$nextTick(() => {
            this.authBusy = false
          })
        })
      if (loginRes) {
        this.error = null
        this.$nextTick(() => {
          // set gates roles
          if (this.$auth.user) {
            this.$gates.setRoles(this.$auth.user.roles)
          }
          if (path) {
            this.$router.push({ path })
          } else {
            const navItem = nav.find(i => i.isDefault)
            this.$router.push({ path: navItem ? navItem.path : '/' })
          }
          //
        })
      }
    }
  }
}
</script>
